.logo-container {
    grid-area: logo;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    display: flex;
}

.logo-letters-container {
    margin: auto;
    font-size: 30px;
    color: var(--anticipation-color);
    white-space: nowrap;
}

@media (min-width: 800px) {
    .logo-letters-container {
        font-size: 45px;
    }
}