.login-container {
    grid-area: login;
    font-size: small;
    text-align: center;
    margin: auto;
    display: flex;
    gap: 5px;
}
.login-button {
    color: var(--anticipation-color);
    border: 1px solid var(--anticipation-color);
    border-radius: 5px;
    background-color: white;
    padding: 5px;
    cursor: pointer;
}
.login-button:hover {
    background-color: var(--anticipation-color);
    color: white;
}
.login-language {
    position: relative;
    margin: auto;
    cursor: pointer;
    --font-size: large;
    border: 1px solid var(--anticipation-color);
    border-radius: 5px;
    background-color: white;
    padding: 5px;
}

