.banner-container {
    grid-area: banner;
    display: block;
    padding: 10px;
    font-size: x-large;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: gray;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    height: max-content;
}
.banner-slogan {
    --white-space: nowrap;
    text-align: center;
    width: 100%;
    display: block;
}
