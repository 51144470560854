.market-container {
    margin: 10px;
    padding: 10px;
    display: grid;
    grid-template-rows: auto 1fr;
    color: white;
    box-shadow: 3px 3px 5px gray;
    border-radius: 5px;
}
.market-container:hover {
    box-shadow: 5px 5px 5px gray;
}

.market-caption {
    font-size: x-large;
    padding-bottom: 10px;
}

.market-description {
    font-size:medium;
}