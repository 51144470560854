.app-container {
  display: grid;
  --border: 1px solid var(--trust-color);
  --padding: 1px;
  border-radius: 5px;
  min-width: min-content;
  min-height: 100%;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
@media (orientation: landscape) {
  .app-container {
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(9, 11.1%);
    grid-template-areas: 
      "logo logo logo banner banner banner banner login login"
      "HRD HRD HRD psychologists psychologists psychologists everyone everyone everyone"
      "safety_manifest safety_manifest safety_manifest safety_manifest safety_manifest safety_manifest safety_manifest safety_manifest safety_manifest"
      "theory theory theory theory theory demo demo demo demo"
      "q_n_a q_n_a q_n_a q_n_a q_n_a q_n_a q_n_a q_n_a q_n_a";
      grid-template-rows: auto auto auto auto 1fr;
    }
}

@media (orientation: portrait) {
  .app-container {
    grid-template-areas: 
      "logo login"
      "banner banner"
      "HRD HRD"
      "psychologists psychologists"
      "everyone everyone"
      "safety_manifest safety_manifest"
      "theory theory"
      "demo demo"
      "q_n_a q_n_a";
      grid-template-rows: auto auto auto auto auto auto auto 1fr;
    }
}
